import { Link, useLocation, useNavigate, useParams, useRoutes, useSearchParams } from "react-router-dom";
import "./DetailedCarUserPageStyles.scss";
import { FC, useContext, useEffect, useRef, useState } from "react";
import Layout from "../../../layout/Layout";
import di from "../../../../../di/DependencyInjection";
import GetCarByIdUseCase, { GetCarByIdUseCaseName } from "../../../../../domain/use_cases/car/GetCarByIdUseCase";
import CarEntity from "../../../../../domain/entities/CarEntity";
import NotResultsComponent from "../../../components/notResults/NotResultsComponent";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import CarCarouselImagesComponent from "../../../components/carCarousel/CarCarouselImagesComponent";
import CurrencyParse from "../../../../utils/CurrencyParse";
import { routes } from "../../../routes/RoutesComponent";
import CarCardComponent from "../../../components/carCard/CarCardComponent";
import SliderComponent from "../../../components/slider/SliderComponent";
import GetRelatedCarsByCardIdUseCase, {
    GetRelatedCarsByCardIdUseCaseName,
} from "../../../../../domain/use_cases/car/GetRelatedCarsByCardIdUseCase";
import Icons from "../../../assets/Icons";
import { BookADateActions } from "../bookADate/BookADatePage";
import { Helmet } from "react-helmet-async";
import BookACarUseCase, { BookACarUseCaseName } from "../../../../../domain/use_cases/book/BookACarUseCase";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import PreviewImage from "./component/PreviewImage";
import ModalAmount from "./component/ModalAmount";
import { InlineWidget, PopupButton } from "react-calendly";
import { Carousel1 } from "../../../components/carousel/carousel";
import { WebShare } from "../../../components/webShare/webShare";
import { Warranty } from "../../../assets/warranty";
import { Alert } from "../../../assets/alert";
import { Arrow } from "../../../assets/arrow";
import { WarrantyStar } from "../../../assets/warrantyStar";
import { CardFinancing } from "../../../components/cardFinancing/cardFinancing";
import { DetailsList } from "./component/detailsList/detailsList";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Button from "../../../components/Button";
import { BuyYourCarModalForm } from "./component/buyYourCarModalForm/buyYourCarModalForm";
import { SuccessfulModal } from "./component/successfulModal/successfulModal";
// import { ModalShowPeritaje } from "./component/ModalShowPeritaje";
import { Expertise } from "./component/expertise/expertise";
import { FeatureList } from "./component/featureList/featureList";

const DetailedCarUserPage: FC<{}> = () => {
    const { id } = useParams<{ id: string }>();
    const [car, setCar] = useState<CarEntity | undefined | null>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const [relatedCars, setRelatedCars] = useState<CarEntity[] | undefined | null>(undefined);
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const [showPeritajeImage, setShowPeritajeImage] = useState(false);
    const [showAmountModal, setShowAmountModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [carsInfo, setCarsInfo] = useState([]);
    const [claimsInfo, setClaimsInfo] = useState(false);
    const [warrantyInfo, setWarrantyInfo] = useState(false);
    const [dropDownList, setDropDownList] = useState(false);
    const [buyYourCarModalForm, setBuyYourCarModalForm] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [quickFinancing, setQuickFinancing] = useState(false);
    const [modalShowPeritaje, setModalShowPeritaje] = useState(false);
    const [description, setDescription] = useState([]);
    const rootElement = useRef(null);
    const navigate = useNavigate();
    const { search } = useLocation();

    const cardFinancing = [
        {
            titlePartOne: "Credi",
            colorOne: "#2b2b2b",
            titlePartTwo: "rápido",
            colorTwo: "#ec671b",
            img: "/assets/icons/time.png",
            subTitle: "Financiación hasta $15 millones en 3 minutos",
            paragraphOne: "Desembolso inmediato",
            paragraphTwo: "Respuesta en 3 minutos",
            paragraphThree: "Sin prenda",
            button: "Financiar separación",
            action: () => {
                setQuickFinancing(true);
            },
        },
        {
            titlePartOne: "Credi",
            colorOne: "#ec671b",
            titlePartTwo: "fácil",
            colorTwo: "#2b2b2b",
            subTitle: "Financiación hasta el 100%",
            img: "/assets/icons/financing.png",
            paragraphOne: "Solicitud Digital",
            paragraphTwo: "Pre-aprobado en 15 minutos",
            paragraphThree: "Atención personalizada",
            button: "Solicitar",
            action: () => {
                window.open("https://app.zapsign.co/verificar/doc/9e5ed5e8-455e-4899-b659-edc6187cfa72", "_blank");
            },
        },
    ];

    const ModalAmountInfo = [
        {
            title: "Separa tu vehículo",
            paragraph:
                "Separa tu vehículo pagando hasta el 100% de su valor o con financiacíon hasta de 15 Millones de pesos.",
            bottonOne: true,
            bottonTwo: true,
        },
        {
            title: "<strong style='font-weight: 800;'>Credi</strong><i style='color: rgb(236, 103, 27);, font-weight: 400;'>rápido</i>",
            paragraph: "Financiación hasta $15 millones en 3 minutos",
            bottonOne: false,
            bottonTwo: true,
        },
    ];

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await fetch(`https://api.wcar.online/api/description-car/${car?.id}/`);
                const data = await res.json();
                setDescription(data);
            } catch (error) {}
        };
        getData();
    }, [car?.id]);

    useEffect(() => {
        fetch(`https://api.wcar.online/api/cars-related/${car?.id}`)
            .then((res) => res.json())
            .then((res) => setCarsInfo(res))
            .catch((e) => console.error(e));
    }, [car?.id]);

    useEffect(() => {
        const from = searchParams.get("from");
        if (from === "meta" && !localStorage.getItem("easy_buy")) {
            setTimeout(() => {
                setBuyYourCarModalForm(true);
            }, 3000);
        }
    }, [car?.id]);

    const _getCar = async () => {
        try {
            const car = await di.get<GetCarByIdUseCase>(GetCarByIdUseCaseName).call(id!);
            setCar(car == null ? null : car);
        } catch (error) {
            setCar(null);
        }
    };

    const capitalizeFirstLetters = (str: string) => {
        const words = str.split(" ");
        const capitalizedWords = words.map((word) => {
            if (word.length > 0) {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
            return word; // Keep empty words as they are
        });
        const result = capitalizedWords.join(" ");
        return result;
    };

    const _getRelatedCars = async () => {
        try {
            const cars = await di.get<GetRelatedCarsByCardIdUseCase>(GetRelatedCarsByCardIdUseCaseName).call(id!);
            setRelatedCars(cars);
        } catch (error) {
            setRelatedCars([]);
        }
    };

    const _bookCarWithPayment = async () => {
        try {
            setShowAmountModal(true);
        } catch (error) {
            addToast("Error al reservar el vehículo", "error", null);
        }
    };

    useEffect(() => {
        if (id) {
            _getCar();
            _getRelatedCars();
        } else {
            setCar(null);
        }
    }, [id]);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        console.log("CAR TAG", car?.tag?.name);
    }, [car]);

    const warrantyMessage = () => {
        if (!car.warranty_type || car.warranty_type === "null") return "Garantía de 6 meses";

        switch (car.warranty_type) {
            case "Garantía de 6 meses o 4000 km":
                return "Garantía de 6 meses";
            case "Garantía de marca o km":
                return "Garantía de fábrica";
            default:
                return "Garantía de 6 meses";
        }
    };

    const location = useLocation();

    useEffect(() => {
        // Instead of reloading, navigate to the same URL

        console.warn("dataasdasd", localStorage.getItem("id"), car?.id, location.pathname);
        if (localStorage.getItem("id") != car?.id) {
            localStorage.setItem("id", car?.id);
            if (car?.id) {
                window.location.reload();
            }
        }
    }, [location.key]); // Use location.key to avoid infinite loop
    return (
        <Layout>
            {(id === undefined || car === null) && <NotResultsComponent />}
            {car === undefined && <LoadingComponent />}
            {car !== null && car !== undefined && (
                <div className="detailed_car_page">
                    <section>
                        <div className="bg_gray py-5">
                            <div className="container">
                                <div className="row d-flex justify-content-center">
                                    {(parseInt(car?.tag?.id) === 5 || parseInt(car?.tag?.id) === 7) && (
                                        <div className="col-12 banner-tag-container">
                                            <div className="banner-tag">
                                                <img
                                                    src="/assets/icons/info.svg"
                                                    alt={car?.name}
                                                    title={car?.name}
                                                    className="img-fluid img_icon me-2"
                                                />
                                                {parseInt(car?.tag?.id) === 5 && (
                                                    <div className="position-relative col-10 col-md-6">
                                                        <p
                                                            onClick={() => setDropDownList(!dropDownList)}
                                                            className="titleDropDonwList"
                                                        >
                                                            <span>Vehículos fuera de estándar:</span> Más al detalle…{" "}
                                                            {dropDownList ? <IoIosArrowUp /> : <IoIosArrowDown />}
                                                        </p>
                                                        <div
                                                            className="position-absolute z-3 mt-2 container_item"
                                                            style={{ display: dropDownList ? "block" : "none" }}
                                                        >
                                                            <p>
                                                                <span className="text-fuera">
                                                                    ¿Qué es un auto fuera de estándar?
                                                                </span>
                                                                La categoría fuera de estándar indica que el vehículo no
                                                                cumple con los criterios de calidad definidos por WCAR
                                                                para ser considerado un automóvil de calidad superior.
                                                                Esto puede deberse a diversos factores, como
                                                                intervención en varias partes de la pintura, problemas
                                                                mecánicos significativos, alto kilometraje, historial de
                                                                pérdida total, daños estructurales u otras razones que
                                                                pueden no estar detalladas aquí. Además, es importante
                                                                destacar que puede o no ser asegurable y esto dependerá
                                                                de las políticas de la aseguradora.
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                {parseInt(car?.tag?.id) === 7 && (
                                                    <p>Este vehículo ya fue reservado por otro usuario.</p>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {parseInt(car?.tag?.id) === 11 && (
                                        <div className="col-12 banner-tag-container">
                                            <div className="banner-tag">
                                                <img
                                                    src="/assets/icons/info.svg"
                                                    alt={car?.name}
                                                    title={car?.name}
                                                    className="img-fluid img_icon me-2"
                                                />
                                                {parseInt(car?.tag?.id) === 11 && (
                                                    <div className="position-relative col-10 col-md-6">
                                                        <p
                                                            onClick={() => setDropDownList(!dropDownList)}
                                                            className="titleDropDonwList"
                                                        >
                                                            <span>Vehículos en preventa:</span> Más al detalle…{" "}
                                                            {dropDownList ? <IoIosArrowUp /> : <IoIosArrowDown />}
                                                        </p>
                                                        <div
                                                            className="position-absolute z-3 mt-2 container_item"
                                                            style={{ display: dropDownList ? "block" : "none" }}
                                                        >
                                                            <p>
                                                                Estamos alistando este vehículo para entregártelo en las
                                                                mejores concisiones.
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className="btn-back-container">
                                        <button onClick={() => navigate(-1)}>
                                            <img
                                                src="/assets/icons/arrow-back.svg"
                                                alt={car?.name}
                                                title={car?.name}
                                                className="img-fluid img_icon me-2"
                                            />
                                            Volver
                                        </button>
                                    </div>
                                    <div className="col-12 col-lg-7 col-xl-8 carCarouselContainer">
                                        <CarCarouselImagesComponent car={car} images={car.images} />
                                    </div>
                                    <div
                                        className="col-12 col-lg-5 col-xl-4 px-0"
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div className="detailed_car_container py-3 px-4 bg-white rounded w-100 mt-5 mt-lg-0">
                                            <div className="mb-4">
                                                <div className="d-flex justify-content-between mb-3">
                                                    <div className="mb-0 ms-1 d-flex">
                                                        <WebShare />
                                                    </div>
                                                    {(car.warranty ||
                                                        (car.warranty_type && car.warranty_type != "null")) && (
                                                        <div className="mb-2 me-2">
                                                            <div
                                                                className={`btn btn_blue btn-detailed-car text-center ${
                                                                    car.warranty_type
                                                                        ? car.warranty_type != "null"
                                                                            ? "warranty2"
                                                                            : "warranty"
                                                                        : "warranty"
                                                                }`}
                                                                style={{
                                                                    width: 220,
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <div style={{ marginLeft: -20 }}>
                                                                    <Warranty fill={"#000"} /> {warrantyMessage()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <h1
                                                    style={{
                                                        fontSize: "32px",
                                                    }}
                                                    className="name-car-detail"
                                                >
                                                    {capitalizeFirstLetters(car.name)}
                                                </h1>
                                                <div
                                                    className="d-flex flex-row mt-2 justify-content-start"
                                                    style={{ fontSize: ".9em" }}
                                                >
                                                    <div className="d-flex align-items-center text_gray p-0">
                                                        <img
                                                            src="/assets/icons/calendar.svg"
                                                            alt={car?.name}
                                                            title={car?.name}
                                                            className="img-fluid img_icon me-2"
                                                        />
                                                        <span className="text-spec">{car.year}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center text_gray p-0 ms-2">
                                                        <img
                                                            src="/assets/icons/odometer.svg"
                                                            alt={car?.name}
                                                            title={car?.name}
                                                            className="img-fluid img_icon me-2"
                                                        />
                                                        <span className="text_nowrap text-spec">
                                                            {car.odometer} Km.
                                                        </span>
                                                    </div>
                                                    <div className="d-flex align-items-center text_gray p-0 ms-2">
                                                        <img
                                                            src="/assets/icons/transmission.svg"
                                                            alt={car?.name}
                                                            title={car?.name}
                                                            className="img-fluid img_icon me-2"
                                                        />
                                                        <span className="text_nowrap text-spec">
                                                            {car.transmission}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <p className="car-spec">
                                                        {car.type.name} / {car.name} {car.year}
                                                    </p>
                                                    <strong className="car-spec-strong d-block mt-1">
                                                        Stock ID:{" "}
                                                        {car?.type_negotiation != null
                                                            ? car?.type_negotiation + car.id
                                                            : car.id}
                                                    </strong>
                                                </div>
                                                <div className="text_ellipsis">
                                                    <h3 className="text_bold car-price">
                                                        {car?.tag?.name !== "Reservado" &&
                                                            CurrencyParse.toCop(car.discount_price ?? car.price)}
                                                    </h3>
                                                    {car.discount_price && (
                                                        <h5 className="text_bold text_gray text_through">
                                                            {CurrencyParse.toCop(car.price)}
                                                        </h5>
                                                    )}
                                                </div>
                                                <div className="d-inline-flex flex-column justify-content-start align-items-start mt-2">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexWrap: "wrap",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        {/* <div
                                                            className="btn btn_cyan font-bold mt-3"
                                                            onClick={() => setShowPeritajeImage(true)}
                                                        >
                                                            VER PERITAJE <Icons.Download />
                                                        </div> */}
                                                        <Button
                                                            className="mt-2"
                                                            size="medium"
                                                            label="VER PERITAJE"
                                                            iconRight={<Icons.Download width={32} />}
                                                            variant="primary"
                                                            onClick={() => setShowPeritajeImage(true)}
                                                            width="190px"
                                                        />

                                                        {/* <Link
                                                            target="_blank"
                                                            to={"https://calendar.app.google/8KmyniuQcAQJeQjp6"}
                                                            className="btn btn_orange_outline my-1 btn-detailed-car "
                                                            style={{ fontSize: 11 }}
                                                        >
                                                            AGENDA TU LLAMADA
                                                            <Icons.ArrowCircle />
                                                        </Link> */}
                                                    </div>

                                                    {/* <PopupButton
                                                        url="https://calendly.com/wcar-1fk/30min"
                                                        className="btn btn_orange_outline my-3 me-3 btn-detailed-car"
                                                        rootElement={rootElement.current}
                                                        text={"AGENDA TU LLAMADA CON UN ASESOR"}
                                                    /> */}
                                                    {/* {true && <InlineWidget url="https://calendly.com/wcar-1fk/30min" />} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="section_2">
                        <div className="black_rectangle">
                            {description.length <= 0 ? (
                                <div className="container bg-white">
                                    <div className="row">
                                        <div className="col-12 col-md-3 bg_black">
                                            <img
                                                src="/assets/recs/lines_box_large.png"
                                                alt={car?.name}
                                                title={car?.name}
                                                className="h-100 img-fluid bg_img"
                                            />
                                            <div className="d-flex flex-column align-items-center p-4">
                                                <div className="side side_top">
                                                    <h3 className="text-white">
                                                        <strong>Características</strong>
                                                        <br />
                                                        <i>del vehículo</i>
                                                    </h3>
                                                </div>
                                                {/* <div
                                            className="btn btn_orange font-bold"
                                            onClick={() => setShowPeritajeImage(true)}
                                        >
                                            VER PERITAJE <Icons.Download />
                                        </div> */}
                                                <Link
                                                    to={
                                                        routes.dateForCar.relativePath +
                                                        "/" +
                                                        BookADateActions.see +
                                                        "/" +
                                                        id
                                                    }
                                                    className="btn btn_orange btn-detailed-car"
                                                >
                                                    QUIERO CONOCERLO <Icons.ArrowCircle />
                                                </Link>
                                            </div>
                                        </div>
                                        <hr className="d-md-none" />
                                        <div className="col-12 col-md-9">
                                            <div className="p-4">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div className="row">
                                                            <div
                                                                className="col-12 my-3 position-relative "
                                                                onClick={() => setClaimsInfo(!claimsInfo)}
                                                            >
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        style={{ width: "24px" }}
                                                                        src="/assets/icons/shield.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Reclamaciones:
                                                                </span>
                                                                <div
                                                                    style={{
                                                                        display: "inline-flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <Alert />
                                                                    <strong style={{ margin: "0 5px" }}>
                                                                        {car.claims ? "SI" : "NO"}
                                                                    </strong>
                                                                    <div
                                                                        className="claims_arrow"
                                                                        style={{
                                                                            transform: claimsInfo
                                                                                ? "rotate(0deg)"
                                                                                : "rotate(180deg)",
                                                                        }}
                                                                    >
                                                                        {car.types_claims && <Arrow />}
                                                                    </div>
                                                                </div>
                                                                {car.types_claims && (
                                                                    <span
                                                                        className="claims position-absolute"
                                                                        style={{
                                                                            display: claimsInfo ? "block" : "none",
                                                                        }}
                                                                    >
                                                                        {JSON.parse(car.types_claims)?.map(
                                                                            (claim: string, i: number) => {
                                                                                return (
                                                                                    <div key={i}>
                                                                                        <p>{claim}</p>
                                                                                        <hr className="claim_line" />
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/star.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Marca:
                                                                </span>
                                                                <strong>{car.brand.name}</strong>
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/reference.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Referencia:
                                                                </span>
                                                                <strong>{car.model}</strong>
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/calendar.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Año:
                                                                </span>
                                                                <strong>{car.year}</strong>
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/type_vehicle.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Carroceria:
                                                                </span>
                                                                <strong>{car.type.name}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <div className="row">
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/door.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Puertas:
                                                                </span>
                                                                <strong>{car.doors}</strong>
                                                            </div>

                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/color.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Color:
                                                                </span>
                                                                <strong>{car.color.name}</strong>
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/motor.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Motor:
                                                                </span>
                                                                <strong>{car.motor}</strong>
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/plate.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Placa:
                                                                </span>
                                                                <strong>{car.plate}</strong>
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/status.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Estado:
                                                                </span>
                                                                <strong>Usado</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-12 col-sm-12">
                                                        <div className="row">
                                                            <div
                                                                className="col-12 my-3 position-relative"
                                                                onClick={() => setWarrantyInfo(!warrantyInfo)}
                                                            >
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        style={{ width: "24px" }}
                                                                        src="/assets/icons/warranty.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Garantía:
                                                                </span>
                                                                <div
                                                                    style={{
                                                                        display: "inline-flex",
                                                                        alignItems: "center",
                                                                        marginTop: "0",
                                                                    }}
                                                                >
                                                                    <Alert />

                                                                    <strong
                                                                        style={{ margin: "0 2px 0 0", height: "18px" }}
                                                                        className="ms-2"
                                                                    >
                                                                        {car.warranty ? "SÍ" : "NO"}
                                                                    </strong>

                                                                    {car.warranty && <WarrantyStar />}

                                                                    <div
                                                                        className="ms-2 claims_arrow"
                                                                        style={{
                                                                            transform: warrantyInfo
                                                                                ? "rotate(0deg)"
                                                                                : "rotate(180deg)",
                                                                        }}
                                                                    >
                                                                        <Arrow />
                                                                    </div>
                                                                </div>
                                                                <span
                                                                    className="warranty_tool_tip position-absolute"
                                                                    style={{ display: warrantyInfo ? "block" : "none" }}
                                                                >
                                                                    Garantía: Te ofrecemos una garantía máxima de seis
                                                                    (6) meses, o 4.000 kilometros, lo que primero
                                                                    ocurra. No cubrimos piezas de desgaste....
                                                                </span>
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/transmission.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Transmisión:
                                                                </span>
                                                                <strong>{car.transmission}</strong>
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/combustible.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Combustible:
                                                                </span>
                                                                <strong>{car.typeOfFuel.name}</strong>
                                                            </div>
                                                            <div className="col-12 my-3">
                                                                <span className="text_gray me-2">
                                                                    <img
                                                                        src="/assets/icons/odometer.svg"
                                                                        className="img-fluid me-2"
                                                                        alt={car?.name}
                                                                        title={car?.name}
                                                                    />
                                                                    Kilometraje:
                                                                </span>
                                                                <strong>{car.odometer}</strong>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {car?.warranty && (
                                                        <div className="col-12 my-3">
                                                            <span className="text_gray me-2">
                                                                <img
                                                                    style={{ width: "24px" }}
                                                                    src="/assets/icons/warranty.svg"
                                                                    className="img-fluid me-2"
                                                                    alt={car?.name}
                                                                    title={car?.name}
                                                                />
                                                                Tipo de garantía:
                                                            </span>
                                                            <div
                                                                style={{
                                                                    display: "inline-flex",
                                                                    alignItems: "center",
                                                                    marginTop: 0,
                                                                }}
                                                            >
                                                                <strong style={{ margin: "0 5px" }}>
                                                                    {car.type_warranty}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 bg-black"></div>
                                        <div className="col-md-9">
                                            <div className="d-flex jusstify-content-center justify-content-md-start p-3">
                                                <Link
                                                    to={routes.contact.relativePath}
                                                    className="button-link btn btn_cyan"
                                                >
                                                    CONTACTA UN ASESOR <Icons.Contact />
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="col-md-2 bg-black"></div>
                                        <div className="col-md-10 bg_gray" style={{ borderRadius: "6px" }}>
                                            <div className="p-5">
                                                <h3 className="text_bold side side_top">
                                                    <strong>Descripción</strong>
                                                </h3>
                                                <div className="description">
                                                    {car?.description?.split("\n")?.map((item, i) => {
                                                        return (
                                                            item !== "\r" && (
                                                                <p key={i} className="item">
                                                                    {item}
                                                                </p>
                                                            )
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="container bg-white">
                                    <div className="row">
                                        <div className="col-12 col-md-3 bg_black">
                                            <div className="d-flex flex-column align-items-center p-4">
                                                <div className="side side_top">
                                                    <h3 className="text-white">
                                                        <strong>Características</strong>
                                                        <br />
                                                        <i>del vehículo</i>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="d-md-none" />
                                        <div className="col-12 col-md-9">
                                            <div className="p-4">
                                                <div className="row">
                                                    <h3 className="text_bold side side_top ms-4">
                                                        <strong>Descripción</strong>
                                                    </h3>
                                                    <FeatureList car={car} description={description} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 bg-black"></div>
                                        <div className="col-md-9">
                                            <div className="d-flex jusstify-content-center justify-content-md-start p-3">
                                                <Link
                                                    to={routes.contact.relativePath}
                                                    className="button-link btn btn_cyan"
                                                >
                                                    CONTACTA UN ASESOR <Icons.Contact />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>

                    <section className="container">
                        {carsInfo.length !== 0 && (
                            <>
                                <div className="container col-md-9 py-5">
                                    <div className="side side_top">
                                        <h2 className="text_bold">
                                            Vehículos<span className="text_orange"> relacionados</span>
                                        </h2>
                                    </div>
                                </div>

                                <section
                                    className="container w-100 d-flex flex-column justify-content-evenly mb-5"
                                    style={{ height: "530px" }}
                                >
                                    <Carousel1 carsRelated={true} cars={carsInfo} />
                                </section>
                            </>
                        )}
                    </section>
                    <div className="container">
                        {relatedCars ? (
                            <SliderComponent
                                responsive={{
                                    mobile: {
                                        breakpoint: { max: 769, min: 0 },
                                        items: 1,
                                        slidesToSlide: 1, // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 769 },
                                        items: 2,
                                        slidesToSlide: 2, // optional, default to 1.
                                    },
                                    desktop: {
                                        breakpoint: { max: 1280, min: 1024 },
                                        items: 3,
                                        slidesToSlide: 3, // optional, default to 1.
                                    },
                                    largeDesktop: {
                                        breakpoint: { max: 3000, min: 1280 },
                                        items: 4,
                                        slidesToSlide: 4, // optional, default to 1.
                                    },
                                }}
                            >
                                {relatedCars?.map((car, index) => (
                                    <div className="m-3" key={index}>
                                        <CarCardComponent car={car} />
                                    </div>
                                ))}
                            </SliderComponent>
                        ) : (
                            <div>loading</div>
                        )}
                    </div>
                    {modalShowPeritaje && (
                        <Expertise
                            setModalShowPeritaje={setModalShowPeritaje}
                            setShowPeritajeImage={setShowPeritajeImage}
                            idColserauto={car?.id_colserauto}
                        />
                    )}
                    {/* {modalShowPeritaje && (
                        <ModalShowPeritaje
                            setModalShowPeritaje={setModalShowPeritaje}
                        />
                    )} */}
                    {showPeritajeImage && (
                        <PreviewImage
                            imageUrl={car?.expertise}
                            idColserauto={car?.id_colserauto}
                            close={() => {
                                setShowPeritajeImage(false);
                            }}
                        />
                    )}
                    {showAmountModal && (
                        <ModalAmount
                            id={id}
                            close={() => setShowAmountModal(false)}
                            carValue={car.discount_price ?? car.price}
                            info={ModalAmountInfo[0]}
                        />
                    )}
                    {quickFinancing && (
                        <ModalAmount
                            id={id}
                            close={() => setQuickFinancing(false)}
                            carValue={car.discount_price ?? car.price}
                            info={ModalAmountInfo[1]}
                        />
                    )}
                    {buyYourCarModalForm && (
                        <BuyYourCarModalForm
                            setBuyYourCarModalForm={setBuyYourCarModalForm}
                            setSuccessModal={setSuccessModal}
                        />
                    )}
                    {successModal && <SuccessfulModal setSuccessModal={setSuccessModal} />}
                </div>
            )}
        </Layout>
    );
};

export default DetailedCarUserPage;
