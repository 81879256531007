import "./DetailPageStyles.scss";
import { FC, useContext, useEffect, useState } from "react";
import Layout from "../../../layout/Layout";
import { useForm } from "react-hook-form";
import Validators from "../../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import { Link, useNavigate } from "react-router-dom";
import Icons from "../../../assets/Icons";
import SocialLoginComponent from "../../../components/socialLogin/SocialloginComponent";
import di from "../../../../../di/DependencyInjection";
import SignInUseCase, { SignInUseCaseName } from "../../../../../domain/use_cases/auth/SignInUseCase";
import { Either, isRight, left } from "fp-ts/lib/Either";
import { routes } from "../../../routes/RoutesComponent";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import ModalConfirmEmail from "../../car/detailedCar/component/ModalConfirmEmail";
import UserContext from "../../../../../domain/providers/user/UserContext";
import UserContextType from "../../../../../domain/providers/user/UserContextType";
import { ContactCard } from "../../../components/contactCard/contactCard";
import HostApi from "../../../../../data/settings/HostApi";
import CarImplDto from "../../../../../data/dto/impl/CarImplDto";
import CarEntity from "../../../../../domain/entities/CarEntity";

const SignInPage: FC<{}> = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [openModalConfirmEmail, setOpenModalConfirmEmail] = useState(false);
    const { user } = useContext(UserContext) as UserContextType;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [car, setCar] = useState("");
    const { addToast } = useContext(ModalsContext) as ModalsContextType;

    const [advisorPhone, setAdvisorPhone] = useState("573018063302");

    const getCarsUser = async () => {
        try {
            const response = await fetch("https://api.wcar.online/api/get-cars-user/", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + HostApi.getToken(),
                },
            }).then((res) => res.json());
            console.log("🚀 ~ const_handleSearch= ~ response:", response);
            const response2 = response.map((item: any) => CarImplDto.fromJson(item.car_user));
            console.log(response2[response2.length - 1]);
            setCar(
                response2[response2.length - 1]?.photoUrl
                    ? response2[response2.length - 1]?.photoUrl
                    : "/assets/wcar-car.jpeg"
            );
        } catch (e) {
            setCar("/assets/wcar-car.jpeg");
        }
    };

    const getAdvisorUser = async () => {
        try {
            const response = await fetch("https://api.wcar.online/api/get-user-advisor/", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + HostApi.getToken(),
                },
            }).then((res) => res.json());
            console.log("🚀 ~ getAdvisorUser= ~ response:", response);
            console.log(response.advisor_details.phone.replace("+", ""));
            setAdvisorPhone(response.advisor_details.phone.replace("+", ""));
        } catch (e) {
            // setCar("/assets/wcar-car.jpeg");
        }
    };

    useEffect(() => {
        setValue("email", user?.email);
        setValue("user", user?.name);
        getCarsUser();
        getAdvisorUser();
    }, []);

    return (
        <div className="position-relative">
            {openModal && (
                <div className="video_conference d-flex justify-content-center align-items-center">
                    <ContactCard setOpenModal={setOpenModal} setOpenForm={setOpenForm} />
                </div>
            )}
            <Layout>
                {loading && (
                    <div className="loading_container">
                        <LoadingComponent />
                    </div>
                )}

                <div className="container ">
                    <div className="side side_top side_blue_orange"></div>
                    <p>wcar</p>
                    <h2 className="text_bold mb-3 mt-6">Tu perfil</h2>
                </div>
                <div className=" position-relative">
                    <div className="container py-4 ">
                        <div style={{ position: "absolute", left: 0, zIndex: -1 }}>
                            <img src="/assets/home/car_02.webp" alt="" />
                        </div>

                        <div className=" d-flex justify-content-center">
                            <div
                                className=" w-100"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <div className="item-detail-user">
                                    <div
                                        className={"d-flex"}
                                        style={{ alignItems: "center", display: "flex", gap: 25 }}
                                    >
                                        <div>
                                            <Icons.UserPhotoIcon />
                                        </div>
                                        <div>
                                            <div
                                                className={"d-flex"}
                                                style={{ alignItems: "center", display: "flex", gap: 50 }}
                                            >
                                                <div className="side side_top side_blue_orange mt-2"></div>
                                                <p style={{ fontSize: 14 }}>Tu perfil</p>
                                            </div>
                                            <h4 style={{ fontSize: 22 }}>{user?.name}</h4>
                                            <p style={{ fontSize: 16, fontWeight: "700" }}>{user?.email}</p>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>

                                <Link className=" mt-3 item-detail-user-image" to={routes.myCarsUserPage.relativePath}>
                                    <img
                                        style={{
                                            height: 304,
                                            objectFit: "cover",
                                            width: "100%",
                                            borderTopLeftRadius: 10,
                                            borderTopRightRadius: 10,
                                        }}
                                        src={car}
                                        alt=""
                                    />
                                    <div className="pt-4  pb-5" style={{ marginLeft: 30 }}>
                                        <div
                                            className={"d-flex"}
                                            style={{ alignItems: "center", display: "flex", gap: 25 }}
                                        >
                                            <div>
                                                <Icons.CarOrange />
                                            </div>
                                            <div>
                                                <h4 style={{ fontSize: 22 }}>Mis vehiculos</h4>
                                                <p style={{ fontSize: 14 }}>
                                                    Información de tu documento de identidad y ocupación
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link className="item-detail-user" to={routes.editUser.relativePath}>
                                    <div
                                        className={"d-flex"}
                                        style={{ alignItems: "center", display: "flex", gap: 25 }}
                                    >
                                        <div>
                                            <Icons.CardUser />
                                        </div>
                                        <div>
                                            <h4 style={{ fontSize: 22 }}>Información Personal</h4>
                                            <p style={{ fontSize: 14 }}>
                                                Información de tu documento de identidad y ocupación
                                            </p>
                                        </div>
                                    </div>
                                    <Icons.CheckUser />
                                </Link>

                                <Link
                                    className="item-detail-user"
                                    to={`https://api.whatsapp.com/send/?phone=${advisorPhone}&text=%C2%A1Hola%21+Quiero+recibir+asesor%C3%ADa+%EF%BF%BD&type=phone_number&app_absent=0`}
                                >
                                    <div
                                        className={"d-flex"}
                                        style={{ alignItems: "center", display: "flex", gap: 25 }}
                                    >
                                        <div>
                                            <Icons.ContactUser />
                                        </div>
                                        <div>
                                            <h4 style={{ fontSize: 22 }}>Contacta un asesor</h4>
                                            <p style={{ fontSize: 14 }}>
                                                Contacta con tu asesor para resolver tus dudas o agendar una cita
                                            </p>
                                        </div>
                                    </div>
                                    <Icons.CheckUser />
                                </Link>

                                <Link
                                    className="item-detail-user"
                                    to={`mailto:servicioposventa@wcar.co?subject=Solicitud de servicio Posventa&body=Servicio Posventa%0A%0APrograma tus servicios posventa en nuestro taller o si necesitas hacer efectiva tu garantía.%0A%0APor favor, proporciona la siguiente información para procesar tu solicitud:%0A- Placa del vehículo:%0A- Nombre:%0A- Número de teléfono:%0A- Razón de la solicitud:`}
                                >
                                    <div
                                        className={"d-flex"}
                                        style={{ alignItems: "center", display: "flex", gap: 25 }}
                                    >
                                        <div>
                                            <Icons.PosVenta />
                                        </div>
                                        <div>
                                            <h4 style={{ fontSize: 22 }}>Servicio Posventa</h4>
                                            <p style={{ fontSize: 14 }}>
                                                Programa tus servicios posventa en nuestro taller o si necesitas hacer
                                                efectiva tu garantía{" "}
                                            </p>
                                        </div>
                                    </div>
                                    <Icons.CheckUser />
                                </Link>
                            </div>
                            {/* <div className="col-md-1"></div> */}
                            {/* <div className="col-md-6 px-0 px-md-3 mb-4">
                            <img
                                src="/assets/pages/profile/bg_profile.jpg"
                                className="img-fluid h-100 object_cover"
                                alt="Inicie sesión"
                                title="Inicie sesión"
                            />
                        </div> */}
                        </div>
                    </div>
                </div>

                {openModalConfirmEmail && (
                    <ModalConfirmEmail
                        login={true}
                        handleBuy={() => {}}
                        close={() => setOpenModalConfirmEmail(false)}
                        email={watch().email}
                    />
                )}
                <div className="d-flex bg-orange " style={{ backgroundColor: "#ec671b", marginTop: 43 }}>
                    <div>
                        <img
                            src="/assets/recs/bg_sell_car.jpeg"
                            alt="wcar"
                            className="h-100"
                            style={{ maxWidth: "650px", width: "100%", objectFit: "cover" }}
                        />
                    </div>
                    <div
                        className="pb-4"
                        style={{ backgroundColor: "#ec671b", marginLeft: 100, marginTop: 70, position: "relative" }}
                    >
                        <img
                            src="./assets/home/lines_2.svg"
                            alt="wcar"
                            title="wcar"
                            style={{ position: "absolute", right: 0, bottom: 0, zIndex: "0" }}
                            loading="lazy"
                            decoding="async"
                        />
                        <div className="side side_top side_blue_neon"></div>
                        <h2 className="text-light">Necesitas Ayudá?</h2>
                        <p className="text-light" style={{ fontSize: 16, marginRight: 100 }}>
                            Ofrecemos asesoría para la compra o venta de tu vehículo, <br />
                            garantizando soluciones claras y estrategias adaptadas <br />a tus necesidades. Contáctanos
                            para asegurarte de tomar <br />
                            la mejor decisión en tu transacción.
                        </p>
                        <Link
                            className="btn btn_cyan mt-4"
                            to={`https://api.whatsapp.com/send/?phone=${advisorPhone}&text=%C2%A1Hola%21+Quiero+recibir+asesor%C3%ADa+%EF%BF%BD&type=phone_number&app_absent=0`}
                        >
                            CONTACTA A UN ASESOR2
                            <Icons.Contact />
                        </Link>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default SignInPage;
