import { Form } from "../../pages/workshopAndService/components/form";
import "./contactCardMecanicoStyles.scss";
import { useState } from "react";

export const ContactCardMecanico = ({ setOpenModal, setOpenForm }) => {
    const [check, setCheck] = useState(1);

    const SelectedOption = () => {
        if (check === 1) {
            setOpenModal(false);
            setOpenForm(true);
        } else if (check === 2) {
            window.open(
                "https://api.whatsapp.com/send/?phone=573018063302&text=%C2%A1Hola%21+Quiero+recibir+asesor%C3%ADa+%EF%BF%BD&type=phone_number&app_absent=0",
                "_blank"
            );
            setOpenModal(false);
        } else if (check === 3) {
            window.open("https://calendar.app.google/8KmyniuQcAQJeQjp6", "_blank");
            setOpenModal(false);
        } else {
            setOpenModal(false);
        }
    };

    return (
        <>
            <div className=" text-white" style={{ width: "100%", maxWidth: 800 }}>
                <div className="top position-relative">
                    <div
                        className="position-absolute"
                        onClick={() => setOpenModal(false)}
                        style={{ top: "30px", right: "30px", cursor: "pointer" }}
                    >
                        <img src="/assets/icons_contact/close_icon.svg" alt="" loading="lazy" decoding="async" />
                    </div>
                    <Form />
                </div>
            </div>
        </>
    );
};
