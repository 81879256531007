import "./newInsurancePageStylesDetail.scss";

import Layout from "../../../layout/Layout";
import { FC, useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet-async";
import ModalGarantie from "../../car/detailedCar/component/ModalGarantie";
import AccordeonComponent from "../../../components/accordeon/AccordeonComponent";
import useKommoForm from "../../../components/segurosModal/useKommoForm";
import useMovilidad from "../../../components/segurosModal/useMovilidad";
import useHogar from "../../../components/segurosModal/useHogar";
import usePymes from "../../../components/segurosModal/usePymes";
import useSalud from "../../../components/segurosModal/useSalud";
import useMascotas from "../../../components/segurosModal/useMascotas";
import ItemNewInsuranceComponent from "./itemNewInsuranceComponent";
import Icons from "../../../assets/Icons";
import { useParams } from "react-router-dom";
import KommoForm from "./formKommo";

interface DetailForm {
    form: any;
}
const NewInsurancePageDetail: FC<{}> = () => {
    const [showGaratieModal, setShowGarantieModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const { insuranceId } = useParams<{ insuranceId: string }>();

    const [detailForm, setDetailForm] = useState<DetailForm>({ form: <></> });

    const cardInfo = {
        side_top: true,
        flex: true,
        titleBlack: `
        En wcar, ofrecemos para ti los más acertados
        seguros de vehículos en Colombia.
        Nuestra filosofía de transparencia brutal
        garantiza que encuentres el seguro perfecto
        para tu carro, sin sorpresas y con la confianza
        que solo wcar puede ofrecer.`,
        imgUrl: "./assets/services/girl-wcar.jpg",
        directionReverse: false,
        cardColor: "#ec671b",
        // cardColor: "#1e1e1e",
        textColor: "#fff",
        cardWidth: "100%",
    };

    const cardInfo2 = {
        flex: true,
        titleBlack:
            "Te recomendamos navegar nuestra web donde encontrarás más información sobre todas las soluciones que ofrecemos en wcar.",
        imgUrl: "./assets/services/rim-modern-car.jpg",

        side_top: true,
        directionReverse: true,
        cardColor: "#ec671b",
        textColor: "#fff",
        cardWidth: "100%",
    };
    const [kommo, setKommo] = useState(false);
    useEffect(() => {
        const scriptContent = `
        !function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1421215",hash:"62d8146dcbec3ec6c452470d162a810c",locale:"es"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");
         `;

        const script = document.createElement("script");
        script.async = true;
        script.innerHTML = scriptContent;
        document.body.appendChild(script);

        const kommoScript = document.createElement("script");
        kommoScript.id = "amoforms_script_1421215";
        kommoScript.async = true;
        kommoScript.src = "https://forms.kommo.com/forms/assets/js/amoforms.js?1734474926";

        setTimeout(() => {
            document.getElementById("form-seguro")?.appendChild(kommoScript);
        }, 1000); // Espera 1 segundo para asegurarse de que todo el DOM esté cargado
    }, []);
    // useEffect(() => {
    //     const scriptContent = `
    //         !function(a,m,o,c,r,m){
    //             a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},
    //             a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},
    //             a[o+r]({id:"1367691",hash:"b23378a4e49fa0254cbff4df090ab7f6",locale:"es"}),
    //             a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}
    //         }(window,0,"amo_forms_","params","load","loaded");
    //     `;

    //     const script = document.createElement("script");
    //     script.async = true;
    //     script.innerHTML = scriptContent;
    //     document.body.appendChild(script);

    //     const kommoScript = document.createElement("script");
    //     kommoScript.id = "amoforms_script_1367691";
    //     kommoScript.async = true;
    //     kommoScript.src = "https://forms.kommo.com/forms/assets/js/amoforms.js?1722876292";

    //     setTimeout(() => {
    //         document.getElementById("form-seguro")?.appendChild(kommoScript);
    //     }, 1000); // Espera 1 segundo para asegurarse de que todo el DOM esté cargado

    //     return () => {
    //         document.body.removeChild(script);
    //     };
    // }, []);
    // useEffect(() => {
    //     // Añade el hash #list a la URL si no existe
    //     if (!window.location.hash) {
    //         window.location.hash = "#form-seguro";
    //     }

    //     // Desplázate al elemento con el ID del hash
    //     const targetElement = document.getElementById("form-seguro");
    //     if (targetElement) {
    //         targetElement.scrollIntoView({ behavior: "smooth" });
    //     }
    // }, []);

    const { formRef } = useKommoForm(openModal);
    const { formRefMovilidad } = useMovilidad();
    const { formRefHogar } = useHogar();
    const { formRefPymes } = usePymes();
    const { formRefSalud } = useSalud();
    const { formRefMascotas } = useMascotas();

    const variablesInsurance = () => {
        let height = ["4", "2", "3"].includes(insuranceId) ? 690 : 800;
        if (insuranceId == "1") {
            height = 712;
        }

        if (insuranceId == "5") {
            height = 600;
        }

        if (insuranceId == "6") {
            height = 700;
        }

        if (insuranceId == "7") {
            height = 800;
        }

        if (insuranceId == "8") {
            height = 690;
        }

        return { height };
    };

    const getInsuranceData = (insuranceId) => {
        const insuranceData = {
            "1": {
                title: "Seguros de Vehículos",
                background:
                    'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("/assets/services/auto.webp")',
                text: "Carros: Protección integral para ti y tu vehículo.\nIncluye grúa y asistencia en carretera.",
                cta: "Cotiza tu Seguro Aquí",
                todoRiesgo: "Cobertura para accidentes, robo, daños a terceros y desastres naturales.",
                beneficios: "Grúa, asistencia en carretera y cobertura para accidentes personales.",
            },
            "2": {
                title: "Seguros de Motos",
                background:
                    'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("/assets/services/motos.webp")',
                text: "Motos: Seguridad y confianza en cada viaje.\nAsistencia mecánica incluida.",
                cta: "Cotiza tu Seguro Aquí",
                todoRiesgo: "Protección contra robo, daños propios y responsabilidad civil.",
                beneficios: "Asistencia mecánica y accidentes personales incluidos.",
            },
            "3": {
                title: "Seguros de Bicicletas",
                background:
                    'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("/assets/services/bicicletas.webp")',
                text: "Bicicletas: Tranquilidad en cada pedalazo.\nIncluye cobertura por robo.",
                cta: "Más Información",
                todoRiesgo: "Cobertura en caso de daños materiales, accidentes o robo parcial/total.",
                beneficios: "Responsabilidad civil por daños a terceros incluida.",
            },
            "4": {
                title: "Seguros de Patinetas",
                background:
                    'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("/assets/services/patinetas.webp")',
                text: "Patinetas: Cobertura para los más prácticos.\nIncluye protección contra accidentes personales.",
                cta: "Cotiza Fácilmente",
                todoRiesgo: "Responsabilidad Civil: Cobertura para accidentes que afecten a terceros.",
                beneficios: "Opciones para accidentes personales y robo.",
            },
            "5": {
                title: "Seguros de Salud",
                background:
                    'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("/assets/services/salud.webp")',
                text: "Salud: Cuidado médico premium.\nCobertura en emergencias internacionales.",
                cta: "Descubre Más",
                todoRiesgo:
                    "Acceso a servicios médicos premium, consultas especializadas y emergencias internacionales.",
                beneficios: "Medicina prepagada con amplias coberturas.",
            },
            "6": {
                title: "Seguros de Mascotas",
                background:
                    'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("/assets/services/mascotas.webp")',
                text: "Mascotas: Porque ellos también lo merecen.\nIncluye consultas y hospitalización veterinaria.",
                cta: "Conoce los Detalles",
                todoRiesgo: "Cobertura veterinaria, hospitalización, y compensación por fallecimiento.",
                beneficios: "Responsabilidad civil por daños causados por tu mascota.",
            },
            "7": {
                title: "Seguros de Hogar",
                background:
                    'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("/assets/services/hogar.webp")',
                text: "Hogar: Protección completa para tu vivienda.\nCobertura por daños a vecinos y asistencia domiciliaria.",
                cta: "Protege tu Hogar Ahora",
                todoRiesgo: "Protección contra incendios, inundaciones y terremotos.",
                beneficios: "Cobertura por robos y daños a vecinos, con asistencia domiciliaria.",
            },
            default: {
                title: "Seguros de PYMES",
                background:
                    'linear-gradient(rgba(0, 0, 0, 0.50), rgba(0, 0, 0, 0.50)), url("/assets/services/pymes.webp")',
                text: "PYMES: Seguridad para tu negocio.\nProtección de bienes y mercancías.",
                cta: "Consulta con un Experto",
                todoRiesgo: "Multirriesgo: Cobertura de bienes empresariales y responsabilidad civil.",
                beneficios: "Protección contra interrupciones del negocio y pérdidas de mercancías.",
            },
        };

        return insuranceData[insuranceId] || insuranceData["default"];
    };

    return (
        <div className="insurance_page">
            {/* {openModal && (
                <div className="video_conference d-flex justify-content-center align-items-center">
                    <SegurosModal
                        setOpenModal={() => setOpenModal(false)}
                        detailForm={detailForm}
                        openModal={openModal}
                    />
                </div>
            )} */}
            <Helmet>
                <title>#1 en Seguros de Vehículos en Colombia </title>
                <meta
                    name="description"
                    content="Conoce todo lo que necesitas saber sobre seguros de vehículos en Colombia, en wcar más que vender te asesoramos."
                />
                <meta
                    name="keywords"
                    content="Seguros de vehículos, Qué es un seguro todo riesgo en Colombia, Necesitas seguro del automóvil, seguro de automóvil"
                />
                <link rel="canonical" href="https://wcar.co/" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Layout>
                <section
                    className="section_1 position-relative"
                    style={{
                        background: getInsuranceData(insuranceId).background,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                >
                    {/* <img
                        src="/assets/services/bg_insurance2.png"
                        alt="wcar"
                        title="wcar"
                        className="img-fluid w-100 bg_img d-block d-md-none"
                    /> */}
                    <div className="content position-md-absolute mt-4 mt-md-0 container-form  ">
                        <div className="container ">
                            <div className="col-md-5 ms-5">
                                <div className="d-none d-md-block side side_top side_blue_neon  " />
                                <h2
                                    className="text_white text_bold text-center text-md-start mb-4"
                                    style={{ fontSize: 36 }}
                                >
                                    {getInsuranceData(insuranceId).title}
                                </h2>
                                <div className="mt-5">
                                    <div>
                                        <div className="side side_top " />
                                        <div>
                                            <h4 className="text-white" style={{ fontSize: 28 }}>
                                                Seguro Todo Riesgo
                                            </h4>
                                            <p className="text-white mt-3" style={{ fontSize: 20 }}>
                                                {getInsuranceData(insuranceId).todoRiesgo}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 mb-3">
                                    <div>
                                        <div className="side side_top " />
                                        <div>
                                            <h4 className="text-white" style={{ fontSize: 28 }}>
                                                Coberturas adicionales
                                            </h4>
                                            <p className="text-white mt-3" style={{ fontSize: 20 }}>
                                                {getInsuranceData(insuranceId).beneficios}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br />
                        </div>
                        <div
                            // id="form-seguro"
                            ref={
                                insuranceId == "1"
                                    ? formRef
                                    : insuranceId == "2" || insuranceId == "3" || insuranceId == "4"
                                    ? formRefMovilidad
                                    : insuranceId == "5"
                                    ? formRefSalud
                                    : insuranceId == "6"
                                    ? formRefMascotas
                                    : insuranceId == "7"
                                    ? formRefHogar
                                    : formRefPymes
                            }
                            className=" "
                            style={{
                                textAlign: "center",
                                zIndex: -1,
                                overflow: "hidden",
                                height: variablesInsurance().height,
                                width: "100%",
                                maxWidth: 600,
                                marginTop: -30,
                            }}
                        ></div>
                    </div>
                    {/* <div id="form-seguro"></div> */}

                    {/* <img
                        src="/assets/services/bg_insurance2.png"
                        className="first_img img-fluid w-100 d-none d-md-block bg_img bg_1"
                        alt="Financiación con wcar"
                        title="Financiación con wcar"
                    /> */}
                </section>
                <div className="p-3 w-full " style={{ backgroundColor: "#ec671b", position: "relative" }}>
                    <h4 style={{ color: "#fff", fontSize: 28, textAlign: "center" }}>
                        Este seguro no es el{" "}
                        <i className={"text_cian"} style={{ color: "#00fefe" }}>
                            SOAT
                        </i>
                    </h4>
                </div>

                {/* <div style={{ height: 1500, padding: 10, background: "red" }}>
                    <div ref={formRefMovilidad}></div>
                </div> */}
                {/* <div id="form-seguro"></div> */}
                {/* <KommoForm /> */}

                {/* <Card cardInfo={cardInfo2} setShowGarantieModal={setShowGarantieModal} setSrvCode={setSrvCode} /> */}
                <section className="workshop_and_service_drop_lists pb-3 pb-md-5 position-relative">
                    <div style={{ position: "absolute", left: 0, zIndex: 1, top: 0 }}>
                        <img src="/assets/home/rectangle-black.png" alt="" />
                    </div>
                    <div className="" style={{ backgroundColor: "#fff" }}>
                        <div
                            className="drop_lists text-black container"
                            style={{
                                zIndex: 10,
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: "white",
                                    minHeight: 200,
                                    paddingBottom: 20,
                                    paddingTop: 40,
                                    paddingLeft: 10,
                                }}
                            >
                                <div>
                                    <div className="side side_top"></div>
                                    <h3>
                                        Seguros de Vehículos{" "}
                                        <i className={""} style={{ color: "#ec671b", fontWeight: 100 }}>
                                            en Colombia{" "}
                                        </i>
                                    </h3>
                                    <p
                                        className="mt-2"
                                        style={{
                                            fontSize: 16,
                                            marginTop: 20,
                                            marginBottom: 20,
                                            fontFamily: "urbanist",
                                        }}
                                    >
                                        Descubre los mejores seguros de vehículos en Colombia con wcar. <br />{" "}
                                        Transparencia brutal, confiabilidad y los mejores aliados para tu carro.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ backgroundColor: "#1e1e1e", position: "relative", zIndex: 10, display: "flex" }}>
                            <img
                                src="/assets/home/lines_2.svg"
                                alt="wcar"
                                title="wcar"
                                style={{ position: "absolute", right: 10, bottom: 10, zIndex: "0" }}
                                loading="lazy"
                                decoding="async"
                            />
                            <img src="/assets/services/section-3-car-seguros.png" alt="" className="img-car" />
                            <div
                                style={{
                                    marginTop: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                <div className=" m-auto p-4">
                                    <div className="side side_top side_blue_neon" />

                                    <h4 style={{ fontSize: 36, color: "#fff", marginBottom: 50 }}>Seguro wcar</h4>

                                    <div className="d-flex " style={{ gap: 10 }}>
                                        <Icons.CarContact width={32} height={32} />
                                        <div>
                                            <h5 style={{ fontSize: 22, color: "#fff" }}>
                                                ¿Necesitas Seguros de <br />
                                                <i style={{ fontWeight: 100 }}>Vehículos en Colombia?</i>
                                            </h5>
                                            <p
                                                style={{
                                                    fontSize: 16,
                                                    color: "#fff",
                                                    marginTop: 10,
                                                    maxWidth: 584,
                                                    width: "100%",
                                                }}
                                            >
                                                Elegir el seguro adecuado para tu vehículo puede ser una tarea compleja.
                                                En wcar, simplificamos el proceso para ti. Te ayudamos a encontrar el
                                                seguro que mejor se adapta a tus necesidades y presupuesto, con la
                                                seguridad y confianza que mereces.
                                            </p>
                                        </div>
                                        <div></div>
                                    </div>

                                    <div className="d-flex " style={{ gap: 10, marginTop: 60 }}>
                                        <Icons.Star width={32} height={32} />
                                        <div>
                                            <h5 style={{ fontSize: 22, color: "#fff" }}>
                                                Seguro de Carro con <br />
                                                <i style={{ fontWeight: 100 }}>Transparencia Brutal</i>
                                            </h5>
                                            <p
                                                style={{
                                                    fontSize: 16,
                                                    color: "#fff",
                                                    marginTop: 10,
                                                    maxWidth: 584,
                                                    width: "100%",
                                                }}
                                            >
                                                En wcar, nos enorgullece nuestra transparencia brutal. Esto significa
                                                que te mostramos todas las opciones disponibles, explicamos claramente
                                                cada póliza y te asesoramos para que tomes la mejor decisión.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="d-flex " style={{ gap: 10, marginTop: 60 }}>
                                        <Icons.LockOrange width={32} height={32} />
                                        <div>
                                            <h5 style={{ fontSize: 22, color: "#fff" }}>
                                                Seguro para Carro <br />
                                                <i style={{ fontWeight: 100 }}>TConfiable y Transparente</i>
                                            </h5>
                                            <p
                                                style={{
                                                    fontSize: 16,
                                                    color: "#fff",
                                                    marginTop: 10,
                                                    maxWidth: 584,
                                                    width: "100%",
                                                }}
                                            >
                                                Confía en wcar para encontrar un seguro de carro confiable y
                                                transparente. Trabajamos con los mejores aliados del sector para
                                                ofrecerte opciones que se adaptan a ti y a tu vehículo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="drop_lists text-black container pt-5 mb-5"
                            style={{
                                zIndex: 10,
                                position: "relative",
                                backgroundColor: "white",
                            }}
                        >
                            <AccordeonComponent
                                title={
                                    <>
                                        <h3>
                                            Preguntas <span>frecuentes</span>
                                        </h3>
                                        <p className="subtitle_drop_list mb-md-3">
                                            Sobre Seguros de Vehículos en Colombia
                                        </p>
                                    </>
                                }
                                options={[
                                    {
                                        title: (
                                            <p className="subtitle_drop_list">
                                                ¿Qué cubre un seguro de todo riesgo para vehículos?
                                            </p>
                                        ),
                                        content: (
                                            <p className="item_drop_list">
                                                N.º 1 en Seguros de Vehículos Un seguro de todo riesgo cubre daños
                                                propios y a terceros, incluyendo robo, accidentes y desastres naturales.
                                                Es la opción más completa para proteger tu vehículo.
                                            </p>
                                        ),
                                    },
                                    {
                                        title: (
                                            <p className="subtitle_drop_list">
                                                ¿Cómo puedo obtener un seguro de vehículo en wcar?
                                            </p>
                                        ),
                                        content: (
                                            <p className="item_drop_list">
                                                En <strong>wcar</strong>, simplemente contáctanos, te asesoraremos en
                                                cada paso del proceso para encontrar el seguro que mejor se adapte a tus
                                                necesidades
                                            </p>
                                        ),
                                    },
                                    {
                                        title: (
                                            <p className="subtitle_drop_list">
                                                ¿Qué debo considerar al elegir un seguro de vehículo?
                                            </p>
                                        ),
                                        content: (
                                            <p className="item_drop_list">
                                                Debes considerar la cobertura, el costo, la reputación de la aseguradora
                                                y tus necesidades específicas. En <strong>wcar</strong>, te ayudamos a
                                                evaluar todas estas variables para tomar la mejor decisión
                                            </p>
                                        ),
                                    },
                                    {
                                        title: (
                                            <p className="subtitle_drop_list">
                                                ¿wcar ofrece seguros de vehículos en toda Colombia?
                                            </p>
                                        ),
                                        content: (
                                            <p className="item_drop_list">
                                                Sí, ofrecemos seguros de vehículos en todo el territorio colombiano.
                                                Nuestro objetivo es brindarte la mejor cobertura sin importar dónde te
                                                encuentres.
                                            </p>
                                        ),
                                    },
                                ]}
                            />
                        </div>

                        <div>
                            {/* <CardInsurance cardInfo={cardInfo} /> */}
                            <div className="d-flex bg-orange " style={{ backgroundColor: "#ec671b", marginTop: 43 }}>
                                <div>
                                    <img
                                        src="/assets/services/girl-wcar.jpg"
                                        alt="wcar"
                                        className="h-100 img-car"
                                        style={{ maxWidth: "650px", width: "100%", objectFit: "cover" }}
                                    />
                                </div>
                                <div className="pb-4 text-ofrecemos">
                                    <img
                                        src="/assets/home/lines_2.svg"
                                        alt="wcar"
                                        title="wcar"
                                        style={{ position: "absolute", right: 0, bottom: 0, zIndex: "0" }}
                                        loading="lazy"
                                        decoding="async"
                                    />
                                    <div className="side side_top side_blue_neon"></div>
                                    <h2 className="text-light">¿Qué ofrecemos en wcar?</h2>
                                    <p
                                        className="text-light"
                                        style={{
                                            fontSize: 16,
                                            marginRight: 100,
                                            width: "95%",
                                            maxWidth: 584,
                                            marginTop: 20,
                                        }}
                                    >
                                        En wcar, ofrecemos para ti los más acertados seguros de vehículos en Colombia.
                                        Nuestra filosofía de transparencia brutal garantiza que encuentres el seguro
                                        perfecto para tu carro, sin sorpresas y con la confianza que solo wcar puede
                                        ofrecer.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* {cardsInfo.map((cardInfo, i) => {
                    return (
                        <Card
                            key={i}
                            cardInfo={cardInfo}
                            setShowGarantieModal={setShowGarantieModal}
                            setSrvCode={setSrvCode}
                        />
                    );
                })} */}
                {/* {showOrangeBanner && <OrangeFestModal setIsOpen={setShowOrangeBanner} />} */}
            </Layout>
            {showGaratieModal && (
                <ModalGarantie
                    id={"185"}
                    close={() => setShowGarantieModal(false)}
                    carValue={100000}
                    SrvCode={"1002"}
                />
            )}
        </div>
    );
};

export default NewInsurancePageDetail;
