import "./SliderStyles.scss";
import Carousel from "react-multi-carousel";
import { FC, useRef } from "react";
import SliderComponentProps from "./SliderComponentProps";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

const SliderComponentAliados: FC<SliderComponentProps> = ({ children, responsive }) => {
    const carouselRef = useRef<any>(null); // Tipado como 'any' porque los tipos de react-multi-carousel no son perfectos

    const previous = () => {
        if (carouselRef.current) {
            carouselRef.current.previous(); // Método nativo para retroceder
        }
    };

    const next = () => {
        if (carouselRef.current) {
            carouselRef.current.next(); // Método nativo para avanzar
        }
    };

    if (!children || children.length === 0) return <div></div>;

    return (
        <div className="slider_component container">
            <div className="w-100">
                <Carousel
                    ref={carouselRef}
                    arrows={false} // Seguimos ocultando las flechas nativas
                    draggable
                    swipeable={true}
                    responsive={responsive} // Usamos el responsive pasado como prop para determinar cuántos items se muestran
                    infinite={true} // Hacer el carrusel infinito
                    slidesToSlide={1} // Desplazarse de uno en uno
                >
                    {children}
                </Carousel>
            </div>
            <div className="mb-3 d-flex align-items-center">
                <div className="d-none d-md-flex me-3">
                    <div onClick={previous} className="arrow_slider previous me-1">
                        <AiOutlineArrowLeft />
                    </div>
                    <div onClick={next} className="arrow_slider next ms-1">
                        <AiOutlineArrowRight />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderComponentAliados;
