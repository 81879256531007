import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./NavbarStyles.scss"; // Custom CSS file for Navbar
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes/RoutesComponent";
import { Collapse, Navbar, Nav, NavItem, NavLink } from "reactstrap";
import UserContext from "../../../../domain/providers/user/UserContext";
import UserContextType from "../../../../domain/providers/user/UserContextType";
import di from "../../../../di/DependencyInjection";
import SignOutUseCase, { SignOutUseCaseName } from "../../../../domain/use_cases/auth/SignOutUseCase";
import TypeVehicleContext from "../../../../domain/providers/typeVehicle/TypeVehicleContext";
import TypeVehicleContextType from "../../../../domain/providers/typeVehicle/TypeVehicleContextType";
import AccountCircleIcon from "../../assets/AccountCircleIcon";
import { IoIosArrowDown } from "react-icons/io";
import LogoutIcon from "../../assets/LogoutIcon";
import Icons from "../../assets/Icons";

const NavbarComponent = () => {
    const [isExpired, setIsExpired] = useState(false);

    const { user, setUser, clearUser } = useContext(UserContext) as UserContextType;
    const { typeVehicles } = useContext(TypeVehicleContext) as TypeVehicleContextType;
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const [timeLeft, setTimeLeft] = useState({
        days: "0",
        hours: "0",
        minutes: "0",
        seconds: "0",
    });

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };
    const _handleAccountButton = async () => {
        if (user?.name) {
            navigate(routes.detailUser.relativePath);
        } else {
            navigate(routes.signIn.relativePath);
        }
    };

    const _handleAccountLogout = async () => {
        if (user == null) {
            navigate(routes.signIn.relativePath);
        } else {
            await di.get<SignOutUseCase>(SignOutUseCaseName).call();
            clearUser();
            navigate(routes.signIn.relativePath);
        }
    };

    // useEffect(() => {
    //     const isShowed = window.localStorage.getItem("orangeFestModal");
    //     if (isShowed) {
    //         setIsShowed(true);
    //     }
    //     if (isShowed) return;
    //     window.localStorage.setItem("orangeFestModal", "true");
    // }, []);

    const [lastScrollY, setLastScrollY] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            console.log(currentScrollY);
            if (currentScrollY > lastScrollY) {
                setIsVisible(false); // Ocultar navbar cuando hace scroll hacia abajo
            } else {
                setIsVisible(true); // Mostrar navbar cuando hace scroll hacia arriba
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [window.scrollY]);
    console.log(window.scrollY);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const target = new Date("02/24/2025");
            const difference = target.getTime() - now.getTime();

            if (difference <= 0) {
                clearInterval(interval);
                setTimeLeft({ days: "00", hours: "00", minutes: "00", seconds: "00" });
                setIsExpired(true); // Oculta la sección cuando el tiempo expira
                return;
            }

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / (1000 * 60)) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            setTimeLeft({
                days: String(days).padStart(2, "0") + ":",
                hours: String(hours).padStart(2, "0") + ":",
                minutes: String(minutes).padStart(2, "0") + ":",
                seconds: String(seconds).padStart(2, "0"),
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {/* {!isExpired && (
                <div
                    style={{ backgroundImage: `url(/assets/navbar-image.png)`, padding: 2, backgroundSize: "cover" }}
                    className={`navbar-popup-desktop ${isVisible ? "show" : "hide"}`}
                >
                    <div style={{ padding: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ display: "flex" }}>
                            <h4
                                style={{
                                    color: "white",
                                    marginRight: 6,
                                }}
                            >
                                maratón wcar
                            </h4>
                            {[
                                { label: "DÍAS", value: timeLeft.days },
                                { label: "HORAS", value: timeLeft.hours },
                                { label: "MIN", value: timeLeft.minutes },
                                { label: "SEG", value: timeLeft.seconds },
                            ].map((item, index) => (
                                <div key={index}>
                                    <div
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderWidth: 2,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h4
                                            style={{
                                                fontSize: 22,
                                                color: "white",
                                                textAlign: "center",
                                                margin: 0,
                                            }}
                                        >
                                            {item.value}
                                        </h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Link
                            className="btn_cyan btn  "
                            style={{ marginLeft: 10 }}
                            to={"https://lu.ma/sr1vuumn"}
                            target="_blank"
                        >
                            RESERVAR CITA AHORA <Icons.ArrowCircle width={32} height={27} />
                        </Link>
                    </div>
                </div>
            )} */}
            {/* <div
                style={{
                    backgroundImage: `url(/assets/navbar-image-mobile.png)`,
                    padding: 2,
                    height: 100,
                    backgroundSize: "cover",
                }}
                className="navbar-popup-mobile"
            >
                <div style={{ padding: 3, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>
                        <div style={{ display: "flex" }}>
                            <h4
                                style={{
                                    color: "white",
                                    marginRight: 6,
                                }}
                            >
                                maratón wcar
                            </h4>
                            {[
                                { label: "DÍAS", value: timeLeft.days },
                                { label: "HORAS", value: timeLeft.hours },
                                { label: "MIN", value: timeLeft.minutes },
                                { label: "SEG", value: timeLeft.seconds },
                            ].map((item, index) => (
                                <div key={index}>
                                    <div
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderWidth: 2,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <h4
                                            style={{
                                                fontSize: 22,
                                                color: "white",
                                                textAlign: "center",
                                                margin: 0,
                                            }}
                                        >
                                            {item.value}
                                        </h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div>
                            <Link
                                className="btn_cyan btn  "
                                style={{ marginLeft: 10 }}
                                to={"https://lu.ma/sr1vuumn"}
                                target="_blank"
                            >
                                RESERVAR CITA AHORA <Icons.ArrowCircle width={32} height={26} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}

            <Navbar className="bg-transparent px-3 w-100 z-2" expand="lg">
                <Link className="navbar-brand me-5" to={routes.home.relativePath}>
                    <img src="/assets/logos/horizontal.svg" title="wcar" alt="wcar" height="50" loading="lazy" />
                </Link>

                <Collapse navbar id="navbarNav" isOpen={isOpen}>
                    <Nav className="ml-auto d-md-flex align-items-center" navbar>
                        <NavItem className="mx-3">
                            <Link className="nav-link" style={{ fontWeight: 500 }} to={routes.aboutUs.relativePath}>
                                Sobre Nosotros
                            </Link>
                        </NavItem>
                        <NavItem className="mx-3">
                            <div className="dropdown">
                                <div className="dropdown-title d-flex align-items-center">
                                    <Link
                                        to={routes.buyOrSell.relativePath}
                                        style={{ fontWeight: 500 }}
                                        className="nav-link d-flex align-items-center"
                                    >
                                        Compra o Vende
                                        <span className="text_reduced ms-1">
                                            <IoIosArrowDown size={18} />
                                        </span>
                                    </Link>
                                </div>
                                <div className="dropdown_content">
                                    <div className="dropdown">
                                        <div className="dropdown-title d-flex align-items-center">
                                            <Link
                                                to={routes.buyYourCar.relativePath}
                                                className="nav-link d-flex align-items-center"
                                            >
                                                Compra tu carro
                                                <span className="ms-1">
                                                    <IoIosArrowDown size={18} />
                                                </span>
                                            </Link>
                                        </div>
                                        <div className="dropdown_content right">
                                            {typeVehicles.map((typeVehicle, index) => (
                                                <Link
                                                    key={index}
                                                    to={
                                                        parseInt(typeVehicle.id) === 3
                                                            ? "/compra-tu-carro/carros-sedan-usados" +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                            : parseInt(typeVehicle.id) === 4
                                                            ? "/compra-tu-carro/carros-híbridos-colombia" +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                            : parseInt(typeVehicle.id) === 6
                                                            ? "/compra-tu-carro/hatchback-colombia" +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                            : parseInt(typeVehicle.id) === 8
                                                            ? "/compra-tu-carro/camionetas-usadas" +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                            : routes.buyYourCar.relativePath +
                                                              "?type_vehicle=" +
                                                              typeVehicle.name
                                                    }
                                                    className="dropdown-item"
                                                >
                                                    <img
                                                        src={typeVehicle.photo}
                                                        alt={typeVehicle.name}
                                                        title={typeVehicle.name}
                                                        className="me-2"
                                                        width={24}
                                                        loading="lazy"
                                                        decoding="async"
                                                    />
                                                    {typeVehicle.name}
                                                    <span className="text_gray">({typeVehicle.count ?? 0})</span>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                    <Link to={routes.buyYourMotorcycle.relativePath} className="nav-link">
                                        Compra tu moto
                                    </Link>
                                    <Link to={routes.sellYourCar.relativePath} className="nav-link">
                                        Vende tu carro
                                    </Link>
                                </div>
                            </div>
                        </NavItem>
                        <NavItem className="mx-3">
                            <div className="dropdown">
                                <div className="dropdown-title">
                                    <div className="nav-link d-flex align-items-center" style={{ fontWeight: 500 }}>
                                        Servicios
                                        <span className="ms-1">
                                            <IoIosArrowDown size={18} />
                                        </span>
                                    </div>
                                </div>
                                <div className="dropdown_content" style={{ zIndex: 99999999 }}>
                                    <Link to={routes.services.relativePath} className="dropdown-item">
                                        Financiación
                                    </Link>
                                    <Link to={routes.insurance.relativePath} className="dropdown-item">
                                        Seguros
                                    </Link>
                                    <Link to={routes.procedures.relativePath} className="dropdown-item">
                                        Trámites
                                    </Link>
                                    <Link to={routes.workshopAndService.relativePath} className="dropdown-item">
                                        Taller
                                    </Link>
                                </div>
                            </div>
                        </NavItem>
                        <NavItem className="mx-3">
                            <Link className="nav-link" to={routes.blog.relativePath} style={{ fontWeight: 500 }}>
                                Blog
                            </Link>
                        </NavItem>
                        <NavItem className="mx-3">
                            <NavLink href={routes.contact.relativePath} style={{ fontWeight: 500 }}>
                                Contacto
                            </NavLink>
                        </NavItem>
                        {user?.name && (
                            <NavItem className="mx-3">
                                <NavLink href={routes.detailedCarUser.relativePath} style={{ fontWeight: 500 }}>
                                    Tus Vehículos
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </Collapse>

                <div className="d-flex navbar_icons">
                    <div
                        className={`btn d-flex align-items-center btn-account ${
                            window.location.pathname == "/thankyou" && "transition"
                        }`}
                        onClick={_handleAccountButton}
                    >
                        <AccountCircleIcon size={33} />
                        <div className="d-none d-xl-block" style={{ marginLeft: "20px" }}>
                            {user?.name ?? "Iniciar sesión"}
                        </div>
                    </div>
                    {user?.name && (
                        <div
                            onClick={_handleAccountLogout}
                            className="d-flex btn btn-cyan"
                            style={{ marginTop: 8, cursor: "pointer" }}
                        >
                            <LogoutIcon />
                        </div>
                    )}

                    <button
                        className="navbar-toggler border-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={toggleNavbar}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </Navbar>
        </>
    );
};

export default NavbarComponent;
