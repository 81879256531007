import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok, FaWhatsapp } from "react-icons/fa";
import "./Linktree.scss"; // Para estilos personalizados opcionales
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LinktreePage = () => {
    const Navigate = useNavigate();
    useEffect(() => {
        window.location.href = "https://wcar.co";
    }, []);
    const links = [
        {
            title: "Compra tu carro",
            url: "https://wa.me/573118131366?text=RRSS%20Estoy%20interesado%20en%20adquirir%20carro%20con%20ustedes,%20%20%C2%BFqu%C3%A9%20me%20puede%20ofrecer?",
            icon: "bi bi-briefcase",
        },
        {
            title: "Vende tu carro",
            url: "https://wa.me/573504715324?text=RRSS%20Estoy%20interesado%20en%20vender%20mi%20carro%20con%20ustedes,%20%20%C2%BFCu%C3%A1nto.%20me%20pueden%20ofrecer?",
            icon: "bi bi-github",
        },
        {
            title: "Cotiza tu seguro",
            url: "https://wa.me/573243997770?text=RRSS%20Estoy%20interesado%20en%20comprar%20un%20seguro,%20%20%C2%BFC%C3%B3mo%20es%20el%20proceso?",
            icon: "bi bi-linkedin",
        },
        {
            title: "Taller",
            url: "https://wa.me/573241024988?text=RRSS%20Estoy%20interesado%20en%20los%20servicios%20de%20taller%20para%20mi%20veh%C3%ADculo%20%C2%BFCu%C3%A1ndo%20puedo%20pasar%20a%20dejarlo?",
            icon: "bi bi-twitter",
        },
        { title: "Página web", url: "https://wcar.co", icon: "bi bi-twitter" },
    ];

    const socialMedias = [
        { title: "WhatsApp", url: "https://wa.me/573018063302", icon: <FaWhatsapp size={30} color="#fff" /> },
        {
            title: "Instagram",
            url: "https://www.instagram.com/wcar.oficial/",
            icon: <FaInstagram size={30} color="#fff" />,
        },
        {
            title: "Facebook",
            url: "https://www.facebook.com/wcarcolombia/",
            icon: <FaFacebook size={30} color="#fff" />,
        },
        { title: "TikTok", url: "https://www.tiktok.com/@wcar.oficial", icon: <FaTiktok size={30} color="#fff" /> },
        {
            title: "LinkedIn",
            url: "https://www.linkedin.com/company/87226549/admin/dashboard/",
            icon: <FaLinkedin size={30} color="#fff" />,
        },
    ];

    return (
        <div className="container-linktree text-center pt-5">
            <div className="profile">
                <img
                    src="/assets/logos/horizontal.svg"
                    alt="wcar"
                    title="wcar"
                    loading="lazy"
                    decoding="async"
                    width={180}
                    height={90}
                />
            </div>
            <div className="container-background">
                <span className="text ">wcar</span>
                <div className="container-line">
                    <div className="line-1"></div>
                    <div className="line-2"></div>
                </div>
                <div className="container-line">
                    <div className="line-3"></div>
                    <div className="line-4"></div>
                </div>
            </div>
            <div className="d-flex justify-content-center mt-4 mb-2" style={{ gap: 10 }}>
                {socialMedias.map((link, index) => (
                    <div
                        style={{
                            width: 45,
                            height: 45,
                            borderRadius: 30,
                            backgroundColor: "black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <a
                            key={index}
                            href={link.url}
                            className={``}
                            style={{
                                width: "100%",
                                maxWidth: "400px",
                                margin: "0 auto",
                                textDecoration: "none",
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>{link.icon}</span>
                        </a>
                    </div>
                ))}
            </div>
            <div className="links mt-4">
                {links.map((link, index) => (
                    <a
                        key={index}
                        href={link.url}
                        className={`btn ${
                            index % 2 === 0 ? "btn_orange" : "btn_gray_light"
                        } d-flex align-items-center justify-content-center mb-3`}
                        style={{ width: "100%", maxWidth: "400px", margin: "0 auto", padding: "10px" }}
                        target="_blank"
                        // rel="noopener noreferrer"
                    >
                        {link.title}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default LinktreePage;
