import "./blackFriday.scss";
import Layout from "../../layout/Layout";
import AccordeonComponent from "../../components/accordeon/AccordeonComponent";
import { Form } from "./components/form";
import CommenstLineComponent from "../../components/commentsLine/CommentsLineComponent";
import { Link } from "react-router-dom";
import { routes } from "../../routes/RoutesComponent";
import Icons from "../../assets/Icons";
import { useEffect, useState } from "react";
import HostApi from "../../../../data/settings/HostApi";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { CarouselIamges } from "../../components/carouselImages/carouselImages";

export const BlackFriday = () => {
    const [outstandingBlog, setOutstandingBlog] = useState(undefined);
    const [timeLeft, setTimeLeft] = useState({
        days: "0",
        hours: "0",
        minutes: "0",
        seconds: "0",
    });

    useEffect(() => {
        _loadBlogs();
    }, []);

    const _loadBlogs = async () => {
        try {
            const response = await HostApi.get("/post");
            const mainPostData = await response.main_post;
            setOutstandingBlog(mainPostData);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const target = new Date("11/24/2024");
            const difference = target.getTime() - now.getTime();

            if (difference <= 0) {
                clearInterval(interval); // Detener el contador cuando alcance la fecha
                setTimeLeft({ days: "00", hours: "00", minutes: "00", seconds: "00" });
                return;
            }

            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            const minutes = Math.floor((difference / (1000 * 60)) % 60);
            const seconds = Math.floor((difference / 1000) % 60);

            setTimeLeft({
                days: String(days).padStart(2, "0"),
                hours: String(hours).padStart(2, "0"),
                minutes: String(minutes).padStart(2, "0"),
                seconds: String(seconds).padStart(2, "0"),
            });
        }, 1000);

        return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
    }, []);

    useEffect(() => {
        const scriptContent = `
       !function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1414215",hash:"18ef4a98d7789e5175e9e32f9b40764e",locale:"es"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");
        `;

        const script = document.createElement("script");
        script.async = true;
        script.innerHTML = scriptContent;
        document.body.appendChild(script);

        const kommoScript = document.createElement("script");
        kommoScript.id = "amoforms_script_1414215";
        kommoScript.async = true;
        kommoScript.src = "https://forms.kommo.com/forms/assets/js/amoforms.js?1731970103";

        setTimeout(() => {
            document.getElementById("kommo-form-container")?.appendChild(kommoScript);
        }, 1000); // Espera 1 segundo para asegurarse de que todo el DOM esté cargado

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="workshop_and_service">
            <img className=" banner" src="/assets/blackFriday/fondo.webp" alt="WCAR" />
            <section className="workshop_and_service_banner">
                <div className="banner_opacity position-absolute"></div>
                <div className="container-black-friday">
                    <div
                        className="sm-enlaces"
                        style={{ display: "flex", gap: 10, position: "absolute", top: 10, right: 20 }}
                    >
                        <Link to="https://www.facebook.com/wcarcolombia?mibextid=ZbWKwL" target="_blank">
                            <div className="sm-item">
                                <FaFacebookF size={24} color="white" />
                            </div>
                        </Link>
                        <Link to="https://www.instagram.com/wcar.oficial?igshid=NGVhN2U2NjQ0Yg%3D%3D" target="_blank">
                            <div className="sm-item">
                                <FaInstagram size={24} color="white" />
                            </div>
                        </Link>
                        <Link to="https://www.youtube.com/@wcarcolombia?si=SAfvApSkimvFnxHK" target="_blank">
                            <div className="sm-item">
                                <FaYoutube size={25} color="white" />
                            </div>
                        </Link>
                        <Link to="https://www.linkedin.com/company/wcarcol/" target="_blank">
                            <div className="sm-item">
                                <FaLinkedinIn size={24} color="white" />
                            </div>
                        </Link>
                    </div>
                    <div className="responsive-container">
                        <div style={{ position: "relative" }}>
                            <img
                                style={{
                                    maxWidth: 300,
                                    maxHeight: "220px",
                                    objectFit: "contain",
                                    width: "70%",
                                    left: 0,
                                }}
                                src="/assets/blackFriday/logo-orange.webp"
                                alt="WCAR"
                            />
                        </div>
                        <img
                            style={{
                                maxWidth: 700,
                                maxHeight: 500,
                                objectFit: "contain",
                                marginLeft: "10%",
                            }}
                            className=" logo-black-friday banner car"
                            src="/assets/blackFriday/carro.webp"
                            alt="WCAR"
                        />
                        <div style={{ width: "100%", maxWidth: 700, marginBottom: 50, marginTop: 300 }}>
                            <h4 style={{ color: "white", textAlign: "center" }}>
                                Black Friday en wcar termina en: <br />
                            </h4>
                            <div
                                style={{
                                    display: "flex",
                                    gap: 10,
                                    marginTop: 20,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    justifyContent: "center",
                                }}
                            >
                                {[
                                    { label: "DÍAS", value: timeLeft.days },
                                    { label: "HORAS", value: timeLeft.hours },
                                    { label: "MIN", value: timeLeft.minutes },
                                    { label: "SEG", value: timeLeft.seconds },
                                ].map((item, index) => (
                                    <div key={index}>
                                        <div
                                            style={{
                                                width: 70,
                                                height: 70,
                                                borderWidth: 2,
                                                borderStyle: "solid",
                                                borderColor: "#00FEFE",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <h4
                                                style={{
                                                    fontSize: 50,
                                                    color: "white",
                                                    textAlign: "center",
                                                    margin: 0,
                                                }}
                                            >
                                                {item.value}
                                            </h4>
                                        </div>
                                        <p
                                            style={{
                                                fontSize: 15,
                                                fontWeight: 700,
                                                color: "white",
                                                textAlign: "center",
                                                marginTop: 3,
                                            }}
                                        >
                                            {item.label}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="responsive-container">
                        <div
                            style={{
                                position: "relative",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            <img
                                style={{
                                    maxWidth: "400px",
                                    maxHeight: "220px",
                                    objectFit: "contain",
                                    width: "100%",
                                }}
                                src="/assets/blackFriday/fechas.webp"
                                alt="WCAR"
                            />
                        </div>

                        <div id="kommo-form-container" className="container-form" style={{ textAlign: "center" }}></div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    <Link to={routes.buyYourCar.relativePath + "?tag=Black Friday %"} className="btn-black btn-orange">
                        VER AUTOS
                        <Icons.ArrowCircle />
                    </Link>{" "}
                </div>

                <div style={{ marginLeft: 20, paddingBottom: 20 }}>
                    <CarouselIamges
                        images={[
                            "/assets/blackFriday/catalogo/1.webp",
                            "/assets/blackFriday/catalogo/2.webp",
                            "/assets/blackFriday/catalogo/3.webp",
                            "/assets/blackFriday/catalogo/4.webp",
                            "/assets/blackFriday/catalogo/5.webp",
                            "/assets/blackFriday/catalogo/6.webp",
                            "/assets/blackFriday/catalogo/7.webp",
                            "/assets/blackFriday/catalogo/8.webp",
                            "/assets/blackFriday/catalogo/9.webp",
                            "/assets/blackFriday/catalogo/10.webp",
                            "/assets/blackFriday/catalogo/11.webp",
                            "/assets/blackFriday/catalogo/12.webp",
                            "/assets/blackFriday/catalogo/13.webp",
                            "/assets/blackFriday/catalogo/14.webp",
                            "/assets/blackFriday/catalogo/15.webp",
                            "/assets/blackFriday/catalogo/16.webp",
                            "/assets/blackFriday/catalogo/17.webp",
                            "/assets/blackFriday/catalogo/18.webp",
                            "/assets/blackFriday/catalogo/19.webp",
                            "/assets/blackFriday/catalogo/20.webp",
                            "/assets/blackFriday/catalogo/21.webp",
                            "/assets/blackFriday/catalogo/22.webp",
                            "/assets/blackFriday/catalogo/23.webp",
                            "/assets/blackFriday/catalogo/24.webp",
                            "/assets/blackFriday/catalogo/25.webp",
                        ]}
                    />
                </div>
            </section>
        </div>
    );
};
