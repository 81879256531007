import React from "react";
import { Link } from "react-router-dom";

function ItemNewInsuranceComponent({ title, subTitle, img, id }) {
    return (
        <Link to={"/seguro-todo-riesgo-vehiculos/" + id} target="_blank">
            <div style={{ width: 280, backgroundColor: "#fff", borderRadius: 10 }}>
                <div>
                    <img src={img} alt="" />
                </div>
                <div className="p-2 pt-3">
                    <h4 className="pb-2">{title}</h4>
                    <p className="pb-2">{subTitle}</p>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button className="btn btn_orange ps-5 pe-5">Cotizar</button>
                    </div>
                    <p className="pt-1">Este seguro no es el SOAT</p>
                </div>
            </div>
        </Link>
    );
}

export default ItemNewInsuranceComponent;
