import "./WorkshopAndServiceStyles.scss";
import Layout from "../../layout/Layout";
import AccordeonComponent from "../../components/accordeon/AccordeonComponent";
import { Form } from "./components/form";
import CommenstLineComponent from "../../components/commentsLine/CommentsLineComponent";
import { Link } from "react-router-dom";
import { routes } from "../../routes/RoutesComponent";
import Icons from "../../assets/Icons";
import { useEffect, useState } from "react";
import HostApi from "../../../../data/settings/HostApi";

export const WorkshopAndService = () => {
    const [outstandingBlog, setOutstandingBlog] = useState(undefined);

    useEffect(() => {
        _loadBlogs();
    }, []);

    const _loadBlogs = async () => {
        try {
            const response = await HostApi.get("/post");
            const mainPostData = await response.main_post;
            setOutstandingBlog(mainPostData);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Layout>
            <div className="workshop_and_service">
                <section className="workshop_and_service_banner">
                    <img
                        className="position-absolute banner"
                        src="/assets/workshopAndService/firs-banner.webp"
                        alt="WCAR"
                    />
                    <div className="banner_opacity position-absolute"></div>
                    <div className="container info_banner">
                        <h2>
                            Taller y Servicio
                            <br />
                            Postventa en <strong>WCAR</strong>
                        </h2>
                    </div>
                </section>
                <section className="workshop_and_service_description py-3 py-md-5">
                    <div className="info_description text-black container">
                        <h3 className="text-black side side_top">
                            <strong>¡Bienvenidos</strong> al taller de wcar!
                        </h3>
                        <p>
                            En <strong style={{ color: "#ec671b" }}>wcar</strong>, ofrecemos un servicio postventa de
                            calidad superior para tu vehículo. Nuestro taller en Bogotá está equipado con{" "}
                            <strong>tecnología de punta</strong> y cuenta con un equipo de expertos dedicados a
                            brindarte la mejor atención posible. Creemos en la <strong>transparencia brutal</strong> y
                            nos comunicamos contigo de manera directa y honesta, asegurando que sepas exactamente qué
                            necesita tu auto y por qué.
                        </p>
                    </div>
                </section>
                <section className="workshop_and_service_drop_lists pb-3 pb-md-5">
                    <div className="drop_lists text-black container">
                        <h3 className="mb-3 mb-md-5">
                            Servicios Postventa <span>Ofrecidos por wcar</span>
                        </h3>
                        <AccordeonComponent
                            title={<p className="title_drop_list">Mantenimiento preventivo</p>}
                            options={[
                                {
                                    title: <p className="subtitle_drop_list">Mantenimiento preventivo</p>,
                                    content: (
                                        <p className="item_drop_list">
                                            Ofrecemos soluciones completas para cualquier problema mecánico, eléctrico o
                                            de carrocería. Ya sea que necesites una reparación menor o mayor, estamos
                                            preparados para ayudarte.
                                        </p>
                                    ),
                                },
                                {
                                    title: (
                                        <p className="subtitle_drop_list">Talleres Especializados por Marca y Modelo</p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            Contamos con talleres especializados para diferentes marcas y modelos, lo
                                            que nos permite ofrecer un servicio personalizado y preciso para tu
                                            vehículo.
                                        </p>
                                    ),
                                },
                            ]}
                        />
                        <br />
                        <br />
                        <AccordeonComponent
                            title={<p className="title_drop_list">Diagnóstico</p>}
                            options={[
                                {
                                    title: <p className="subtitle_drop_list">Equipos de Diagnóstico Especializados</p>,
                                    content: (
                                        <p className="item_drop_list">
                                            Utilizamos tecnología avanzada de diagnóstico para identificar problemas de
                                            manera rápida y precisa, asegurando que tu auto reciba el tratamiento
                                            adecuado.
                                        </p>
                                    ),
                                },
                            ]}
                        />
                        <br />
                        <br />
                        <AccordeonComponent
                            title={<p className="title_drop_list">Garantías</p>}
                            options={[
                                {
                                    title: (
                                        <p className="subtitle_drop_list">
                                            Reparación o Reemplazo de Piezas Defectuosas
                                        </p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            En wcar, te ofrecemos garantías que cubren la reparación o el reemplazo de
                                            piezas defectuosas, brindándote tranquilidad y confianza en nuestros
                                            servicios.
                                        </p>
                                    ),
                                },
                            ]}
                        />
                        <br />
                        <br />
                        <AccordeonComponent
                            title={<p className="title_drop_list">Asesoría Técnica</p>}
                            options={[
                                {
                                    title: (
                                        <p className="subtitle_drop_list">
                                            Orientación sobre Uso y Mantenimiento del Vehículo
                                        </p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            Nuestro equipo está aquí para ofrecerte asesoría técnica sobre el uso y
                                            mantenimiento de tu auto, ayudándote a prolongar su vida útil y mejorar su
                                            rendimiento.
                                        </p>
                                    ),
                                },
                            ]}
                        />
                        <br />
                        <br />
                        <AccordeonComponent
                            title={<p className="title_drop_list">Otros Servicios</p>}
                            options={[
                                {
                                    title: <p className="subtitle_drop_list">Venta de Repuestos Originales</p>,
                                    content: (
                                        <p className="item_drop_list">
                                            Como distribuidores autorizados, garantizamos la venta de repuestos
                                            originales de alta calidad para tu vehículo, asegurando su compatibilidad y
                                            funcionamiento óptimo.
                                        </p>
                                    ),
                                },
                                {
                                    title: <p className="subtitle_drop_list">Venta de Accesorios</p>,
                                    content: (
                                        <p className="item_drop_list">
                                            Personaliza tu auto con nuestros accesorios, desde rines deportivos hasta
                                            avanzados equipos de sonido. Transformamos tu vehículo en una extensión de
                                            tu personalidad.
                                        </p>
                                    ),
                                },
                                {
                                    title: <p className="subtitle_drop_list">Planes de Mantenimiento</p>,
                                    content: (
                                        <p className="item_drop_list">
                                            Nuestros contratos de mantenimiento prepagos te permiten disfrutar de
                                            servicios a precio fijo, evitando sorpresas y ayudándote a mantener tu auto
                                            en perfectas condiciones.
                                        </p>
                                    ),
                                },
                                {
                                    title: <p className="subtitle_drop_list">Asistencia en Carretera</p>,
                                    content: (
                                        <p className="item_drop_list">
                                            Si tu auto sufre una avería en la vía pública, estamos aquí para ayudarte
                                            con nuestra asistencia en carretera, disponible en todo Bogotá.
                                        </p>
                                    ),
                                },
                                {
                                    title: <p className="subtitle_drop_list">Lavado y Detailing</p>,
                                    content: (
                                        <p className="item_drop_list">
                                            Ofrecemos servicios de lavado y detailing para que tu auto luzca siempre
                                            impecable, tanto por dentro como por fuera.
                                        </p>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </section>
                <section className="workshop_and_service_gallery container py-3 py-md-5 d-flex flex-wrap">
                    <img className="image image_1" src="/assets/workshopAndService/gallery-3.jpg" alt="WCAR" />
                    <img className="image image_2" src="/assets/workshopAndService/gallery-2.webp" alt="WCAR" />
                    <img className="image image_3" src="/assets/workshopAndService/gallery-4.jpg" alt="WCAR" />
                    <img className="image image_4" src="/assets/workshopAndService/gallery-1.jpg" alt="WCAR" />
                </section>
                <section className="workshop_and_service_form">
                    <div className="container" id={"form-taller"} style={{ maxWidth: "800px" }}>
                        <Form />
                    </div>
                </section>
                <section className="workshop_and_service_testimonials">
                    <CommenstLineComponent />
                </section>
                {/* <section className="workshop_and_service_questions py-3 py-md-5">
                    <div className="drop_lists container">
                        <AccordeonComponent title={(<p className="title_drop_list">Preguntas <span style={{ color: "#ec671b" }}>frecuentes</span></p>)} options={[
                            {
                                title: (<p className="subtitle_drop_list">¿Cuál es el tiempo estimado de reparación para mi vehículo?</p>),
                                content: (<p className="item_drop_list"></p>)
                            },
                            {
                                title: (<p className="subtitle_drop_list">¿Cuál es el costo aproximado de la reparación que necesita mi auto?</p>),
                                content: (<p className="item_drop_list"></p>)
                            },
                            {
                                title: (<p className="subtitle_drop_list">¿Qué tipo de garantía ofrecen en las reparaciones realizadas?</p>),
                                content: (<p className="item_drop_list"></p>)
                            },
                            {
                                title: (<p className="subtitle_drop_list">¿Utilizan repuestos originales o genéricos en las reparaciones?</p>),
                                content: (<p className="item_drop_list"></p>)
                            },
                        ]} />
                    </div>
                </section> */}
                {outstandingBlog && (
                    <section className="workshop_and_service_blog">
                        <img
                            src={outstandingBlog?.photoUrl}
                            alt="wcar"
                            title="wcar"
                            className="img-fluid object_cover w-100 outstandingBlog-main-image"
                        />
                        <div className="container">
                            <div className="outstanding_blog ms-md-5">
                                <div className="row">
                                    <Link
                                        to={
                                            routes.detailedBlog.relativePath +
                                            `/${
                                                outstandingBlog.url_post
                                                    ? outstandingBlog.url_post.replace(/ /g, "-")
                                                    : outstandingBlog.title.replace(/ /g, "-")
                                            }?id=${outstandingBlog.id}`
                                        }
                                        className="col-md-6 ms-md-5"
                                    >
                                        <div className="bg_white outstanding_container p-4">
                                            <h1 className="my-2 text_bold outstanding-title">
                                                {outstandingBlog?.title}
                                            </h1>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1 blog_info_user">
                                                    <span className="text_gray date">
                                                        {new Intl.DateTimeFormat("es-ES", {
                                                            year: "numeric",
                                                            month: "long",
                                                            day: "numeric",
                                                        }).format(new Date(outstandingBlog.created_at))}
                                                    </span>
                                                </div>
                                                <div className="text_orange">
                                                    <Icons.ArrowCircle width={60} />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </div>
        </Layout>
    );
};
