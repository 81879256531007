import { Link, useLocation } from "react-router-dom";
import Icons from "../../assets/Icons";
import Layout from "../../layout/Layout";
import "./Error404PageStyles.scss";
import { routes } from "../../routes/RoutesComponent";

const ThankyouPage: React.FC = () => {
    const { state } = useLocation();
    const { login } = state ?? false;
    return (
        <div className="error_404_page">
            <Layout>
                <div className="bg_gray">
                    <div className="wrapper">
                        <img src="/assets/pages/error404/wcar.svg" alt="wcar" title="wcar" className="src_1" />
                        <img src="/assets/pages/error404/rombos.svg" className="src_2 d-none d-md-block" />
                        <div className="content">
                            {/* <span className="text_404">
                                4<span className="text_yellow">0</span>4
                            </span> */}
                            <h1>¡Gracias por elegir wcar!</h1>
                            <span>
                                Hemos recibido tu información y estamos emocionados de acompañarte en esta experiencia.
                                Nuestro equipo se pondrá en contacto contigo muy pronto para seguir adelante. ¡Estamos
                                encantados de ayudarte!
                            </span>
                            <div>
                                <Link
                                    to={login ? routes.signIn.relativePath : routes.home.relativePath}
                                    className="btn btn_orange mt-3"
                                >
                                    {login ? "INICIAR SESIÓN" : "REGRESA AL HOME"}
                                    <Icons.ArrowCircle />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default ThankyouPage;
